// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buscar-propiedad-js": () => import("./../../../src/pages/buscarPropiedad.js" /* webpackChunkName: "component---src-pages-buscar-propiedad-js" */),
  "component---src-pages-comunicados-js": () => import("./../../../src/pages/comunicados.js" /* webpackChunkName: "component---src-pages-comunicados-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publicar-propiedad-js": () => import("./../../../src/pages/publicarPropiedad.js" /* webpackChunkName: "component---src-pages-publicar-propiedad-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/BlogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-propiedad-template-js": () => import("./../../../src/templates/PropiedadTemplate.js" /* webpackChunkName: "component---src-templates-propiedad-template-js" */)
}

